import { Map } from '../Maps/Map.js';
import { PinClusterer } from '../PinClusterer/PinClusterer.js';
import { Type, assertType, assertInstance } from '../Util/Assertions.js';
import { RenderTarget, RenderTargetOptions } from './RenderTarget.js';

class MapRenderTargetOptions extends RenderTargetOptions {
  constructor() {
    super();

    this.idForEntity = entity => 'js-yl-' + entity.profile.uid;
    this.map = null;
    this.pinBuilder = (pinOptions, entity, index) => pinOptions.build();
    this.pinClusterer = null;
  }

  withIdForEntity(idForEntity) {
    assertType(idForEntity, Type.FUNCTION);

    this.idForEntity = idForEntity;
    return this;
  }

  /**
   * map: SearchMap
   */
  withMap(map) {
    assertInstance(map, Map);

    this.map = map;
    return this;
  }

  withPinBuilder(pinBuilder) {
    assertType(pinBuilder, Type.FUNCTION);

    this.pinBuilder = pinBuilder;
    return this;
  }

  withPinClusterer(pinClusterer) {
    assertInstance(pinClusterer, PinClusterer);

    this.pinClusterer = pinClusterer;
    return this;
  }

  build() {
    return new MapRenderTarget(this);
  }
}

class MapRenderTarget extends RenderTarget {
  constructor(options) {
    assertInstance(options, MapRenderTargetOptions);

    super(options);

    if (!options.map) {
      return Promise.reject(new Error('map is null or undefined'));
    }

    this._idForEntity = options.idForEntity;
    this._map = options.map;
    this._pinBuilder = options.pinBuilder;
    this._pinClusterer = options.pinClusterer;

    this._pins = {};
  }

  getPins() {
    return { ...this._pins };
  }

  /**
   * async render(data) => SearchMap
   * Calls map update function with data for pins, then returns map element
   */
  async render(data) {
    if (this._pinClusterer) {
      this._pinClusterer.reset(false);
    }

    Object.values(this._pins).forEach(pin => pin.remove());
    this._pins = {};

    const verticalResults = ANSWERS.core.storage.get("vertical-results");
    var searchCoords = verticalResults?.map?.mapCenter; //TODO: debug here for coords (near me works but not normal geo)


    var nearMeFilter = verticalResults?.appliedQueryFilters?.find((filter)=>{return filter.value === "near me"});
    if (nearMeFilter) {
     var coordInfo = nearMeFilter.filter?.['c_linkedAffiliatePCRMAdvisor.builtin.location']?.['$near'] || {};
      searchCoords = { latitude: coordInfo.lat, longitude: coordInfo.lng };
    }

    //Loop over to add distances to each branch
    (data.response.entities || []).forEach((entity) => {
      if (entity.profile.c_linkedAffiliatePCRMAdvisor) {
        entity.profile.c_linkedAffiliatePCRMAdvisor.forEach((branch) => {
          branch.distance = getMilesDistanceFromCoords(searchCoords, branch.yextDisplayCoordinate);
        })
      }
    });

    var radius = adjustRadius(data.response.entities);
    const advisorsByBranch = getLinkedAdvisors(data.response.entities);

    //Loop over entity and branches to add each pin individually
    (data.response.entities || []).forEach((entity, index) => {
      if (entity.profile.c_linkedAffiliatePCRMAdvisor) {
        entity.profile.c_linkedAffiliatePCRMAdvisor.forEach((branch, j) => {
          //Add additional fields for pinBuilder/map making, since branches are linked to main search results
          branch.profile = JSON.parse(JSON.stringify(branch));//deep copy so it doesn't recurse
          branch.profile.uid = branch.id;
          branch.linkedAdvisors = advisorsByBranch[branch.id];
          if (branch.distance <= radius) {
            const pin = this._pinBuilder(this._map.newPinOptions(), branch, index + j + 1);
            if (pin) {
              this._pins[this._idForEntity(branch)] = pin;
            }
          }
        });
      }
    });

    const pins = Object.values(this._pins);
    const coordinates = pins.map(pin => pin.getCoordinate());

    if (coordinates.length && data.fitCoordinates) {
      this._map.fitCoordinates(coordinates);
    }

    if (this._pinClusterer) {
      this._pinClusterer.cluster(pins, this._map);
    } else {
      pins.forEach(pin => pin.setMap(this._map));
    }
    return this._map;
  }
}

export {
  MapRenderTarget,
  MapRenderTargetOptions
};

function getMilesDistanceFromCoords(c1, c2) {
  if (!c1.latitude || !c1.longitude || !c2.latitude || !c2.longitude) {
    return -1;
  }
  //Calculate distance between two sets of latitude and longitude into miles apart
  const R = 3958.8; // Radius of the Earth in miles
  var dLat = toRad(c2.latitude-c1.latitude);
  var dLon = toRad(c2.longitude-c1.longitude);
  var lat1 = toRad(c1.latitude);
  var lat2 = toRad(c2.latitude);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return Value * Math.PI / 180;
}

// Calculate dynamic radius so map isn't flooded with pins
// Stops when number of pins is less than 100 or radius is 25 miles
function adjustRadius(entities) {
  var radius = 200; //default radius in miles
  var numPins = entities.reduce((fullList, entity) => {
    return fullList.concat(entity.profile.c_linkedAffiliatePCRMAdvisor);
  }, []).filter((a) => {
    return a && a.distance < radius;
  }).length;

  while (numPins > 100 && radius > 5){
    radius = (radius > 25) ? radius-25 : radius-5;
    numPins = entities.reduce((fullList, entity) => {
      return fullList.concat(entity.profile.c_linkedAffiliatePCRMAdvisor);
    }, []).filter((a) => {
      return a && a.distance < radius;
    }).length;
  }
  return radius;
}

function getLinkedAdvisors(entities) {
  var map = {};
  entities.forEach((entity) => {
    if (entity.profile.c_linkedAffiliatePCRMAdvisor) {
      entity.profile.c_linkedAffiliatePCRMAdvisor.forEach((branch) => {
        if (map[branch.id]){
          map[branch.id].push(entity.profile);
        } else {
          map[branch.id] = [entity.profile];
        }
      });
    }
  });
  return map;
}